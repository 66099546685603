/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { OfficeApibaseURL } from 'apps/bayada/constants';
import { contentfulClient } from 'apps/bayada/config/contentful-client';

/**
 * Constructs a URL route string based on the provided office details and optional service.
 *
 * The function creates a formatted URL path for an office location, incorporating the office's mailing address and an optional service.
 * The URL is constructed to be lowercased, sanitized (removing non-alphanumeric characters), and formatted with hyphens for spaces.
 *
 * @param office - An object representing the office, which includes various address components and an `officeId`:
 *   - `mailingAddressState`: The state portion of the office's mailing address.
 *   - `mailingAddressCity`: The city portion of the office's mailing address.
 *   - `mailingAddress1`: The primary street address.
 *   - `mailingAddress2`: An additional street address (if applicable).
 *   - `officeId`: A unique identifier for the office.
 * @param service - An optional string representing a service associated with the office. This will be included in the route if provided.
 *
 * @returns A string representing the constructed URL route. The format of the route is:
 *   - `/service/locations/state/city/street/officeId`
 *
 * The service and address components are formatted to be URL-friendly:
 * - Leading and trailing spaces are removed.
 * - All characters are converted to lowercase.
 * - Non-alphanumeric characters are replaced with spaces.
 * - Spaces are replaced with hyphens.
 */
export const constructOfficePageRoute = (office: any, service?: string) => {
  const _service = service
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/g, ' ')
    ?.replace(/\s/g, '-');
  const _state = office?.mailingAddressState
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _city = office?.mailingAddressCity
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _street = (
    office?.mailingAddress1?.trim() +
    ' ' +
    office?.mailingAddress2?.trim()
  )
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const route = `/${_service}/locations/${_state}/${_city}/${_street}/${office?.officeId}`;
  return route;
};

/**
 * Fetches office locations based on the provided latitude, longitude, proximity, and optional services.
 * @param {number | (() => number) | undefined} lat - The latitude coordinate of the location.
 * @param {number | (() => number) | undefined} long - The longitude coordinate of the location.
 * @param {string | undefined} proximity - The proximity range for office search.
 * @param {string[] | undefined} services - Optional services to filter the search results.
 * @returns {Promise<any>} - A Promise that resolves with the response data containing office locations.
 */
export const getOfficeLocations = async (
  lat: number | (() => number) | undefined,
  long: number | (() => number) | undefined,
  proximity: string | undefined,
  baseURL?: string
) => {
  const instance = axios.create({
    baseURL: baseURL ? baseURL : OfficeApibaseURL
  });
  if (lat && long) {
    const response = await instance.get(
      `/v1/OfficeData/${lat}/${long}/${proximity}`
    );
    if (response?.data?.ResponseCode !== 200) {
      throw new Error('Network response was not ok');
    }
    return response?.data;
  }
};

/**
 * Retrieves the latitude and longitude coordinates for a given address using the Google Maps Geocoding API.
 * @param {string | null} address - The address for which to retrieve coordinates.
 * @param {string | undefined} apiKey - The API key for accessing the Google Maps Geocoding API.
 * @returns {Promise<any>} - A Promise that resolves to the response containing the latitude and longitude coordinates.
 */
export const getLocLatLong = async (
  address: string | null | undefined,
  apiKey: string | undefined
) => {
  try {
    if (!address || !apiKey) {
      throw new Error('Address or API key is missing');
    }

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error!`);
    }

    const data = response.json();
    return data;
  } catch (error) {
    console.error('Error fetching location data:', error);
    return null;
  }
};

/**
 * The function `getOfficeById` fetches office data by ID and handles errors appropriately.
 * @param {string} id - The `id` parameter in the `getOfficeById` function is a string that represents
 * the unique identifier of the office you want to retrieve information for.
 * @returns The function `getOfficeById` is returning the data fetched from the specified API endpoint
 * for the office with the given `id`. If the fetch operation is successful and the response is ok, it
 * returns the JSON data received from the API. If there is an error during the fetch operation or if
 * the response is not ok, it will log the error to the console.
 */
export const getOfficeById = async (id: string) => {
  if (id) {
    try {
      const instance = axios.create({
        baseURL: OfficeApibaseURL
      });
      const officeRes = await instance.get(
        OfficeApibaseURL + `/v1/OfficeData/GetByOfficeId/${id}`
      );
      if (officeRes.data?.ResponseCode !== 200) {
        throw new Error('Network response was not ok');
      }
      const data = await officeRes?.data;

      return data;
    } catch (error) {
      console.log(` ${id} :: getOfficeById error -->`, error);
    }
  } else {
    console.log(`${id} is null --- getOfficeById`);
  }
};

/* The function `getLicenseList` fetches office License Entries from contentful.*/
export const getLicenseList = async () => {
  try {
    const contentful = contentfulClient();
    const licensesListResponse = await contentful?.getEntries({
      content_type: 'officeLicense',
      include: 10
    });
    const data = licensesListResponse?.items.map((list: any) => {
      return list.fields;
    });
    return data;
  } catch (error) {
    console.log('no licenses added');
  }
};
